import * as React from 'react'

export function TikTokEmbed({ videoNumber }: { videoNumber: string }) {
  return (
    <div
      className="block center"
      style={{
        left: 0,
        margin: 'auto',
        width: '325px',
        height: '738px',
        position: 'relative',
      }}
    >
      <iframe
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          border: 0,
          background: 'inherit',
        }}
        src={`https://www.tiktok.com/embed/${videoNumber}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  )
}
