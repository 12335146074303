import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { TikTokEmbed } from './TikTokEmbed'

export function ExampleBlog() {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h1">First Blog</Typography>
      <Typography variant="body1">
        Right here is where we can put whatever you want. It just goes on and on and on. Later we can make this smarter
        but for now you just write here whatever you want. I am including a youtube video below. Hi I am a new line of
        code. Type here
      </Typography>
      <TikTokEmbed videoNumber="7214197162610019630" />
      <TikTokEmbed videoNumber="7210055674267651374" />
      <Typography variant="body1">
        Of course after the video we have to talk about and explain it. So i am including that down here.
      </Typography>
    </Box>
  )
}
